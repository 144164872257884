import { apPy } from "System/system";
import store from "System/mainStore";

// -------------------------------------------------------
// -------------------------------------------------------

export const runParsers = (rawfiles, parsers) => async (dispatch) => {
  const files = rawfiles.map((rawfile) => ({
    file: `${rawfile.path}/${rawfile.name}`,
    cut: rawfile.cut
  }));

  const { list } = store.getState().mapping;
  parsers = parsers.map( parser => {
    if(parser.datatype === "key-value") {
      const parsedData = {};
      const mappingItem = list.find((item) => item._id === parser.mapping);
      mappingItem.mappingData.forEach((item, itemindex) => {
        if (itemindex > 0) {
          parsedData[item[0].trim()] = item[1].trim();
        }
      });
      return {
        ...parser,
        data: parsedData,
      }
    }
    return parser;
  });

  parsers = parsers.map(parser => {
    if(parser.datatype === "float" || parser.datatype === "str") {
      return {
        ...parser,
        steps: parser.steps.map(step => {
          return {
            ...step,
            fields: step.fields.map(field => {
                const which_type = typeof field;
                return { which_type, field };
            })
          }
        })
      }
    }
    return parser;
  })

  console.log("Modified parsers", parsers);
  
  if (parsers && parsers.length) {
    dispatch({
      type: "INIT_MAIN_PROGRESS",
      title: "Parsing files...",
      total: files.length,
    });
    for (let ii in files) {
      dispatch({
        type: "UPDATE_MAIN_PROGRESS",
        current: ii,
        status: `Procssing file #${ii}`,
      });
      await apPy("parse","custom", {
        file: files[ii].file,
        cut: files[ii].cut,
        parsers: JSON.stringify(parsers),
      });
    }
    dispatch({ type: "CLOSE_MAIN_PROGRESS" });
  }
};
