const initialState = {
    user: null,
    lastUpdate: "April 2, 2025. 1000 CET.",
    version: "0.275",
    note: null,
    dbaccess: false,
  };
  
  // -------------------------------------------------------
  // -------------------------------------------------------
  const reducer = ( state = initialState, action ) => {
  
    // -------------------------------------------------------
    switch(action.type) {
  
      // --------------------------------------------------------------
      case "SIGN_IN":
        return { ...state, user: action.user }
  
      // --------------------------------------------------------------
      case "NOTIFY":
        return { ...state, note: action.note }
  
      // --------------------------------------------------------------
      case "SET_DB_ACCESS":
        return { ...state, dbaccess: action.dbaccess }
  
      // -------------------------------------------------------
      default:
        return state;
  
    }
  }
  
  export default reducer;