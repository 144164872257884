import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import Number from "Components/Number/";
// -------------------------------------------------------------
import { getQC } from "Redux/qualitycontrol/actions";
// -------------------------------------------------------------
import waitbar from "Media/waitbar.gif";
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingA, setLoadingA] = useState(false);
  const [loadingB, setLoadingB] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [loadingD, setLoadingD] = useState(false);
  const projects = useSelector((state) => state.projects);

  const {
    project,
    project: { collectionsRepo, hasFrame },
  } = projects;
  const { qca, qcb, qcc, qcd } = useSelector((state) => state.qualitycontrol);
  const data = { qca, qcb, qcc, qcd, loadingA, loadingB, loadingC, loadingD };

  if (!hasFrame) {
    return (
      <div className="notification is-warning is-light">
        No frame found. Please build a frame to continue.
      </div>
    );
  }

  const getAllQC = async () => {
    setLoading(true);
    setLoadingA(true);
    await dispatch(getQC("a"));
    setLoadingA(false);
    setLoadingB(true);
    await dispatch(getQC("b"));
    setLoadingB(false);
    setLoadingD(true);
    await dispatch(getQC("d"));
    setLoadingD(false);

    setLoading(false);
  };

  return (
    <div>
      <div className=" buttons">
        <button
          className={`button is-small ${loading ? "is-loading" : ""}`}
          onClick={getAllQC}
        >
          <span className="icon">
            <i className="fa-duotone fa-cogs"></i>
          </span>
          <span>Generate Overview Tables</span>
        </button>
      </div>
      <QC data={data} which="a" />
      <QC data={data} which="b" />
      <QC data={data} which="d" />
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const QC = ({ data, which }) => {
  let qc, loadingWhich, QCWhich;

  switch (which) {
    case "a":
      qc = data.qca;
      loadingWhich = data.loadingA;
      QCWhich = QCAData;
      break;
    case "b":
      qc = data.qcb;
      loadingWhich = data.loadingB;
      QCWhich = QCBData;
      break;
    case "c":
      qc = data.qcc;
      loadingWhich = data.loadingC;
      QCWhich = QCCData;
      break;
    case "d":
      qc = data.qcd;
      loadingWhich = data.loadingD;
      QCWhich = QCDData;
      break;
  }

  return (
    qc !== null && (
      <div className="box">
        {loadingWhich ? (
          <div className="waitbar-wrapper">
            <img src={waitbar} alt="waitbar" />
          </div>
        ) : (
          <QCWhich qc={qc} />
        )}
      </div>
    )
  );
};

const QCAData = ({ qc }) => {
  qc = qc.sort((a, b) => b.total_royalty - a.total_royalty);
  const total_royalty = qc.reduce((acc, item) => acc + item.total_royalty, 0);
  const total_line_count = qc.reduce((acc, item) => acc + item.number_of_matching_rows, 0);
  const total_number_of_files = qc.reduce((acc, item) => acc + item.number_of_files, 0);
  const total_number_of_contracts = qc.reduce((acc, item) => acc + item.number_of_contracts, 0);
  return (
    <table className="table is-striped is-narrow">
      <thead>
        <tr>
          <th>Payor</th>
          <th>Number of Contracts</th>
          <th>Line Count</th>
          <th>Number of Files</th>
          <th>Currency</th>
          <th>Total Royalty</th>
        </tr>
      </thead>
      <tbody>
        {qc.map((item) => (
          <tr key={item.payor}>
            <td>
              <div className="tag is-info is-light">{item.payor}</div>
            </td>
            <td className="has-text-right">
              <Number value={item.number_of_contracts} />
            </td>
            <td className="has-text-right">
              <Number value={item.number_of_matching_rows} />
            </td>
            <td className="has-text-right">
              <Number value={item.number_of_files} />
            </td>
            <td className="has-text-centered">{item.currency}</td>
            <td className="has-text-right">
              <Number value={item.total_royalty} />
            </td>
          </tr>
        ))}
        <tr>
          <td>Total</td>
          <td className="has-text-right is-total">
            <Number value={total_number_of_contracts} />
          </td>
          <td className="has-text-right is-total">
            <Number value={total_line_count} />
          </td>
          <td className="has-text-right is-total">
            <Number value={total_number_of_files} />
          </td>
          <td></td>
          <td className="has-text-right is-total">
            <Number value={total_royalty} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const QCBData = ({ qc }) => {
  qc = qc.sort((a, b) => a.payor.localeCompare(b.payor));
  const total_royalty = qc.reduce((acc, item) => acc + item.total_royalty, 0);
  const total_line_count = qc.reduce((acc, item) => acc + item.number_of_matching_rows, 0);
  const total_number_of_files = qc.reduce((acc, item) => acc + item.number_of_files, 0);
  return (
    <table className="table is-striped is-narrow">
      <thead>
        <tr>
          <th>Payor</th>
          <th>Contract</th>
          <th>Number of Files</th>
          <th>Line Count</th>
          <th>Currency</th>
          <th>Total Royalty</th>
        </tr>
      </thead>
      <tbody>
        {qc.map((item) => (
          <tr key={item.payor}>
            <td>
              <div className="tag is-info is-light">{item.payor}</div>
            </td>
            <td className="has-text-centered">
              <div className="tag is-primary is-light">{item.contract}</div>
            </td>
            <td className="has-text-right">
              <Number value={item.number_of_files} />
            </td>
            <td className="has-text-right">
              <Number value={item.number_of_matching_rows} />
            </td>
            <td className="has-text-centered">{item.currency}</td>
            <td className="has-text-right">
              <Number value={item.total_royalty} />
            </td>
          </tr>
        ))}
        <tr>
          <td>Total</td>
          <td></td>
          <td className="has-text-right is-total">
            <Number value={total_number_of_files} />
          </td>
          <td className="has-text-right is-total">
            <Number value={total_line_count} />
          </td>
          <td></td>
          <td className="has-text-right is-total">
            <Number value={total_royalty} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const QCCData = ({ qc }) => {
  console.log(qc);
  if (qc.length == 0) return <div>No data</div>;
  qc = qc.sort((a, b) => a.payor.localeCompare(b.payor));
  const time_points = Object.keys(qc[0].time_series).sort();

  return (
    <div className="table-container">
      <table className="table is-striped">
        <thead>
          <tr>
            <th>Payor</th>
            <th>Contract</th>
            {time_points.map((time_point) => (
              <th key={time_point}>{time_point.replace(/ /g, "\u00A0")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {qc.map((item) => (
            <tr key={item.payor}>
              <td>
                <div className="tag is-info is-light">{item.payor}</div>
              </td>
              <td className="has-text-centered">
                <div className="tag is-primary is-light">{item.contract}</div>
              </td>
              {time_points.map((time_point) => (
                <td key={time_point}>
                  <Number value={item.time_series[time_point]} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const QCDData = ({ qc }) => {
  console.log(qc);
  return (
    <div>
      {qc.monthly.length > 0 && (
        <div>
          <h2 className="is-size-5">Monthly</h2>
          <DTable entry={qc.monthly} />
        </div>
      )}
      {qc.quarterly.length > 0 && (
        <div>
          <h2 className="is-size-5">Quarterly</h2>
          <DTable entry={qc.quarterly} />
        </div>
      )}
      {qc["half-yearly"].length > 0 && (
        <div>
          <h2 className="is-size-5">Half-Yearly</h2>
          <DTable entry={qc["half-yearly"]} />
        </div>
      )}
      {qc.yearly.length > 0 && (
        <div>
          <h2 className="is-size-5">Yearly</h2>
          <DTable entry={qc.yearly} />
        </div>
      )}
    </div>
  );
};

const DTable = ({ entry }) => {
  const time_points = Object.keys(entry[0].time_series);
  return (
    <div className="box">
      <div className="table-container">
        <table className="table is-striped">
          <thead>
            <tr>
              <th>Payor</th>
              <th>Contract</th>
              {time_points.map((time_point) => (
                <th key={time_point}>{time_point.replace(/ /g, "\u00A0")}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {entry.map((item) => (
              <tr key={item.payor}>
                <td>
                  <div className="tag is-info is-light">{item.payor}</div>
                </td>
                <td>
                  <div className="tag is-primary is-light">{item.contract}</div>
                </td>
                {time_points.map((time_point) => (
                  <td key={time_point}>
                    <Number value={item.time_series[time_point]} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
