import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HotTable } from "@handsontable/react";
// -------------------------------------------------------------
import copyToClipboard from "System/copyToClipboard";
// -------------------------------------------------------------
import { updateUpdateObjects } from "Redux/collections/actions";
import { notify } from "Redux/user/actions";
import { getCSVInfo } from "Redux/files/actions";
import currencies from "Data/currencies";
import Checker from "Components/Checker";
import { updateHeadersShort } from "Data/updateHeaders";
// -------------------------------------------------------------
import "./css.css";
import "./handsontable.full.css";
// -------------------------------------------------------------
import CollectionsProcessFrame from "Components/CollectionsProcessFrame";
// -------------------------------------------------------------
// -------------------------------------------------------------

const allowedCurrencies = currencies.map((x) => x.currency.toUpperCase());
allowedCurrencies.push("USD");

const readOnly = [
  "File_Name_9LC",
  "Year_Statement_9LC",
  "Half_Statement_9LC",
  "Quarter_Statement_9LC",
  "Month_Statement_9LC",
  "Statement_Period_Half_9LC",
];

const orderedColumns__ = [
  "File_Name_9LC",
  "Catalogue_9LC",
  "Third_Party_9LC",
  "Statement_Period_9LC",
  "Distribution_Date_9LC",
  "Payout_Currency_9LC",
  "Rights_Type_9LC",
  "Contract_ID_9LC",
  "Year_Statement_9LC",
  "Half_Statement_9LC",
  "Quarter_Statement_9LC",
  "Month_Statement_9LC",
  "Statement_Period_Half_9LC",
  "Management_Note_9LC",
  "Statement_End_Date_9LC",
];

const Exported = () => {
  return (
    <CollectionsProcessFrame>
      <Index />
    </CollectionsProcessFrame>
  );
};

export default Exported;

const monthMapper = {
  "01": { q: "Q1", h: "H1" },
  "02": { q: "Q1", h: "H1" },
  "03": { q: "Q1", h: "H1" },
  "04": { q: "Q2", h: "H1" },
  "05": { q: "Q2", h: "H1" },
  "06": { q: "Q2", h: "H1" },
  "07": { q: "Q3", h: "H2" },
  "08": { q: "Q3", h: "H2" },
  "09": { q: "Q3", h: "H2" },
  10: { q: "Q4", h: "H2" },
  11: { q: "Q4", h: "H2" },
  12: { q: "Q4", h: "H2" },
};
// -------------------------------------------------------------
const quarterMapper = {
  Q1: "H1",
  Q2: "H1",
  Q3: "H2",
  Q4: "H2",
};

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {
  const [info, setInfo] = useState(null);
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const {
    project: { collectionsRepo, flatFilesReverse },
  } = projects;
  const nav = useSelector((state) => state.nav);
  const { collectionid } = nav;

  if (!collectionid) {
    return null;
  }
  const collection = collectionsRepo[collectionid];

  const reverseUpdateHeaders = {};
  updateHeadersShort.forEach((header, index) => {
    reverseUpdateHeaders[header] = index;
  });

  const columns = updateHeadersShort.map((header, headerindex) => {
    return { readOnly: readOnly.includes(header.header) };
  });
  const { fileids } = collection;
  const data = fileids.map((fileid) => {
    const row = [];
    updateHeadersShort.forEach((header) => {
      row.push(flatFilesReverse[fileid].updateObject[header.header]);
    });
    return row;
  });

  const beforeChange = (changes, source) => {
    let error = null;
    console.log("Changes are", changes);
    const changesArray = [];

    for (var ii = 0; ii < changes.length; ii++) {
      let [row, col, dummy, value] = changes[ii];
      const fileid = fileids[row];

      const changingHeader = updateHeadersShort[col].header;
      console.log("Changing header is", changingHeader);

      if (changingHeader === "Statement_Period_9LC") {
        value = value ? value.toUpperCase() : "";
        const match = value.match(
          /^(\d{4})(?:\s+((?:H[1-2]|Q[1-4]|(?:0[1-9]|1[0-2])))?)?$/
        );
        let proceed = false;
        if (match) {
          const year = parseInt(match[1]);
          if (year > 1990 && year <= new Date().getFullYear()) {
            proceed = true;
            changesArray.push({
              col: "Year_Statement_9LC",
              value: year,
              fileid,
            });
            const detailedMatch = value.match(
              /(\d{4})\s+((?:H[1-2]|Q[1-4]|(?:0[1-9]|1[0-2])))/
            );
            if (detailedMatch) {
              const det = detailedMatch[2];
              if (det.substr(0, 1) === "Q") {
                const h = quarterMapper.hasOwnProperty(det)
                  ? quarterMapper[det]
                  : "";
                changesArray.push({
                  col: "Half_Statement_9LC",
                  value: h,
                  fileid,
                });
                changesArray.push({
                  col: "Quarter_Statement_9LC",
                  value: det,
                  fileid,
                });
                changesArray.push({
                  col: "Month_Statement_9LC",
                  value: "",
                  fileid,
                });
                changesArray.push({
                  col: "Statement_Period_Half_9LC",
                  value: `${year} ${h}`,
                  fileid,
                });
              }
              if (parseInt(det)) {
                console.log(`Det is >${det}<`);
                const h = monthMapper.hasOwnProperty(det)
                  ? monthMapper[det].h
                  : "";
                const q = monthMapper.hasOwnProperty(det)
                  ? monthMapper[det].q
                  : "";

                changesArray.push({
                  col: "Half_Statement_9LC",
                  value: h,
                  fileid,
                });
                changesArray.push({
                  col: "Quarter_Statement_9LC",
                  value: q,
                  fileid,
                });
                changesArray.push({
                  col: "Month_Statement_9LC",
                  value: det,
                  fileid,
                });
                changesArray.push({
                  col: "Statement_Period_Half_9LC",
                  value: `${year} ${h}`,
                  fileid,
                });
              }
              if (det.substr(0, 1) === "H") {
                changesArray.push({
                  col: "Half_Statement_9LC",
                  value: det,
                  fileid,
                });
                changesArray.push({
                  col: "Quarter_Statement_9LC",
                  value: "",
                  fileid,
                });
                changesArray.push({
                  col: "Month_Statement_9LC",
                  value: "",
                  fileid,
                });
                changesArray.push({
                  col: "Statement_Period_Half_9LC",
                  value: `${year} ${det}`,
                  fileid,
                });
              }
            }
            if (!detailedMatch) {
              changesArray.push({
                col: "Half_Statement_9LC",
                value: "",
                fileid,
              });
              changesArray.push({
                col: "Quarter_Statement_9LC",
                value: "",
                fileid,
              });
              changesArray.push({
                col: "Month_Statement_9LC",
                value: "",
                fileid,
              });
              changesArray.push({
                col: "Statement_Period_Half_9LC",
                value: "",
                fileid,
              });
            }
          }
        }
        changesArray.push({
          col: "Statement_Period_9LC",
          value: proceed ? value : "",
          fileid,
        });
        changes[ii][3] = proceed ? value : "";
        if (!proceed) {
          error =
            "Invalid Entry. Periods must be of the format YYYY H/Q/M. Years must be in the range between 1990 and now.";
          changesArray.push({ col: "Year_Statement_9LC", value: "", fileid });
          changesArray.push({ col: "Half_Statement_9LC", value: "", fileid });
          changesArray.push({
            col: "Quarter_Statement_9LC",
            value: "",
            fileid,
          });
          changesArray.push({ col: "Month_Statement_9LC", value: "", fileid });
          changesArray.push({
            col: "Statement_Period_Half_9LC",
            value: "",
            fileid,
          });
        }
      }

      if (changingHeader === "Payout_Currency_9LC") {
        value = value ? value.toUpperCase() : "";
        let error = "Invalid Entry. Currency must be one of the supported set.";
        if (allowedCurrencies.includes(value)) {
          changesArray.push({ col: "Payout_Currency_9LC", value, fileid });
          changes[ii][3] = value;
          error = null;
        }
        if (error) {
          alert(error);
          changesArray.push({ col: "Payout_Currency_9LC", value: "", fileid });
          changes[ii][3] = "";
        }
      }

      if (changingHeader === "Distribution_Date_9LC") {
        if (value) {
          value = value.trim();
          let error =
            "Invalid Entry. Distribution Date must be in the format YYYY-MM-DD.";

          const dateRegex = /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])$/;
          if (dateRegex.test(value)) {
            const date = new Date(value);
            if (
              date.toString() !== "Invalid Date" &&
              date.toISOString().slice(0, 10) === value
            ) {
              changesArray.push({
                col: "Distribution_Date_9LC",
                value,
                fileid,
              });
              changes[ii][3] = value;
              error = null;
            }
          }

          if (error) {
            alert(error);
            changesArray.push({
              col: "Distribution_Date_9LC",
              value: "",
              fileid,
            });
            changes[ii][3] = "";
          }
        }
      }

      if (
        !["Statement_Period_9LC", "Payout_Currency_9LC"].includes(
          changingHeader
        )
      ) {
        changesArray.push({ col: changingHeader, value, fileid });
        if (changes.length === 1 && (col === 1 || col === 2)) {
          for (var cc = 0; cc < fileids.length; cc++) {
            changesArray.push({
              col: changingHeader,
              value,
              fileid: fileids[cc],
            });
          }
        }
      }
    }
    if (error) {
      alert(error);
    }

    // Apply all changes at once
    dispatch(updateUpdateObjects(changesArray));

    // changesArray.forEach(change => {
    //   dispatch(updateUpdateObject(change.col, change.value, change.fileid));
    // });
  };

  const afterSelection = async (row, col) => {
    if (col === 0) {
      const file = flatFilesReverse[fileids[row]];
      setInfo(null);
      const fileInfo = await getCSVInfo(file);
      setInfo(fileInfo);
    }
  };

  const filedata = null;

  return (
    <>
      <div className="box" style={{ marginRight: 50 }}>
        <Checker collectionids={[collectionid]} />
      </div>
      <div className="hands-on-wrapper">
        <HotTable
          data={data}
          width="80%"
          height="auto"
          stretchH="all"
          licenseKey="non-commercial-and-evaluation"
          colHeaders={updateHeadersShort.map((x) => x.header)}
          beforeChange={beforeChange}
          afterSelection={afterSelection}
          columns={columns}
        />
      </div>
      <div
        className={`popup ${info === null ? "closed" : "open"}`}
        onClick={() => {
          if (info) setInfo(null);
        }}
      >
        <div
          className="box"
          onClick={(evt) => {
            evt.stopPropagation();
          }}
        >
          {info ? (
            <div className="box table-container update-file">
              <table className="table is-small is-fullwidth is-striped is-hoverable is-bordered">
                <thead>
                  <tr>
                    {info.headers.map((header, headerindex) => {
                      return (
                        <th key={`${header}-${headerindex}`}>
                          {header.replace(/ /g, "\u00A0")}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {info.head.map((headRow, headIndex) => {
                    return (
                      <tr key={`head-row-${headIndex}`}>
                        {info.headers.map((header) => {
                          return (
                            <td
                              key={`${header}-${headIndex}`}
                              onClick={(evt) => {
                                const copied = evt.target.innerText;
                                copyToClipboard(copied);
                                dispatch(
                                  notify(`Copied to clipboard`, copied, 1.5)
                                );
                              }}
                            >
                              {clean(headRow[header])}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const clean = (str) => {
  if (typeof str !== "string") return str;
  if (str === null) return null;
  return str.replace(/ /g, "\u00A0");
};
