const initialState = {
    qca: null,
    qcb: null,
    qcc: null,
    qcd: null,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = ( state = initialState, action ) => {

  // -------------------------------------------------------
  switch(action.type) {
    case "SET_QC":
        switch(action.which) {
            case "a":
                return { ...state, qca: action.data };
            case "b":
                return { ...state, qcb: action.data };
            case "c":
                return { ...state, qcc: action.data };
            case "d":
                return { ...state, qcd: action.data };
        }
    // -------------------------------------------------------
    default:
      return state;
  }
}

export default reducer;