import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const columns = [
  "Catalogue_9LC",
  "Third_Party_9LC",
  "Statement_Period_9LC",
  "Payout_Currency_9LC",
  "Contract_ID_9LC",
  "Distribution_Date_9LC",
];

const mandatoryColumns = [
  "Catalogue_9LC",
  "Third_Party_9LC",
  "Payout_Currency_9LC",
  "Statement_Period_9LC",
  "Contract_ID_9LC",
];

const CheckerTR = ({ collectionid, showCollection = false }) => {
  const [status, setStatus] = useState(null);
  const [summary, setSummary] = useState(null);

  const projects = useSelector((state) => state.projects);
  const {
    project: { collectionsRepo, flatFilesReverse },
  } = projects;

  const collection = collectionsRepo[collectionid];
  const { fileids } = collection;

  useEffect(() => {
    if (fileids) {
      const data = fileids.map((fileid) => ({
        ...flatFilesReverse[fileid].updateObject,
      }));
      const thisStatus = {};
      for (let ii = 0; ii < columns.length; ii++) {
        thisStatus[columns[ii]] = getStatus(data, columns[ii]);
      }
      setStatus(thisStatus);
      const thisSummary = mandatoryColumns.every(
        (column) => thisStatus[column]
      );
      setSummary(thisSummary);
    }
  }, [fileids, flatFilesReverse]);

  if (!collectionid) {
    return null;
  }

  if (!status) return null;
  return (
    <tr>
      {showCollection && (
        <td>
          <div className="tag is-info is-light">{collection["headline"]}</div>{" "}
        </td>
      )}
      <td>
        <div
          className={`tag ${
            summary
              ? status["Distribution_Date_9LC"]
                ? "is-success"
                : "is-warning"
              : "is-danger"
          }`}
        >
          &nbsp; &nbsp;
          {summary ? (
            status["Distribution_Date_9LC"] ? (
              <i className="fa-duotone fa-check success-icon" />
            ) : (
              <i className="fa-duotone fa-warning warning-icon" />
            )
          ) : (
            <i className="fa-duotone fa-times danger-icon" />
          )}
          &nbsp; &nbsp;
        </div>
      </td>
      {columns.map((column) => {
        return (
          <td key={`column-${column}`} className="has-text-centered">
            <i
              className={`fa-duotone ${
                status[column] ? "fa-check" : "fa-times"
              }`}
            />
          </td>
        );
      })}
    </tr>
  );
};

const Checker = ({ collectionids, showCollection = false }) => {
  if (!collectionids) return null;
  return (
    <div className="table-container">
      <table className="table is-fullwidth is-striped">
        <thead>
          <tr>
            {showCollection && <td>Collection</td>}
            <td>Status</td>
            {columns.map((column) => {
              return <th key={column}>{column}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {collectionids.map((collectionid) => (
            <CheckerTR
              key={collectionid}
              collectionid={collectionid}
              showCollection={showCollection}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default Checker;

// ------------------------------------------------------------
// ------------------------------------------------------------
const getStatus = (data, column) => {
  let status = true;
  for (let ii = 0; ii < data.length; ii++) {
    if (data[ii][column] === null || data[ii][column] === "") status = false;
  }
  return status;
};
