import { apPy } from "System/system";
import store from "System/mainStore";
// -------------------------------------------------------

// -------------------------------------------------------
// -------------------------------------------------------
export const getQC = (which) => async (dispatch) => {
  const { project } = store.getState().projects;
  const { datadir } = project;

  dispatch({ type: "SET_QC", which, data: "generating" });
  const res = await apPy("qc", which, { datadir });
  if(res.data) {
    dispatch({ type: "SET_QC", which, data:res.data });
  }
};

