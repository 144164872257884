import md5 from "md5";
import { engine, apPy, backend } from "System/system";
import { download, uploadAndReadCSV } from "System/system";
// -------------------------------------------------------
import store from "System/mainStore";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// -------------------------------------------------------
// -------------------------------------------------------
export const deFrac = (allfiles, cb) => async (dispatch) => {
  allfiles = allfiles.filter((file) => file.ext === "CSV");
  const files = allfiles.map((x) => x.name);
  const paths = allfiles.map((x) => x.path.replace(/\./g, ""));
  const cuts = allfiles.map((x) => x.cut);
  for (const ii in files) {
    await apPy("files", "deFrac", {
      file: files[ii],
      path: paths[ii],
      cut: cuts[ii],
    });
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const removeZeros = (allfiles, cb) => async (dispatch) => {
  allfiles = allfiles.filter((file) => file.ext === "CSV");
  const files = allfiles.map((x) => x.name);
  const paths = allfiles.map((x) => x.path.replace(/\./g, ""));
  const cuts = allfiles.map((x) => x.cut);
  for (const ii in files) {
    await apPy("files", "strip_leading_zeros", {
      file: files[ii],
      path: paths[ii],
      cut: cuts[ii],
    });
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const downloadCollection = (allfiles, cb) => async (dispatch) => {
  allfiles = allfiles.filter((file) => file.ext === "CSV");
  const files = allfiles.map((x) => x.name);
  const paths = allfiles.map((x) => x.path.replace(/\./g, ""));

  const user = store.getState().user.user;
  const { project } = store.getState().projects;
  const { datadir, _id } = project;

  const zipid = md5(user + Date.now() + Math.random());
  console.log(datadir, zipid);

  await apPy("files", "zip_up_collection", {
    files: JSON.stringify(files),
    paths: JSON.stringify(paths),
    datadir,
    zipid,
    projectid: _id,
  });

  download(`collection_${_id}_${zipid}`);

  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const deEuropize = (allfiles, cb) => async (dispatch) => {
  allfiles = allfiles.filter((file) => file.ext === "CSV");
  const files = allfiles.map((x) => x.name);
  const paths = allfiles.map((x) => x.path.replace(/\./g, ""));
  const cuts = allfiles.map((x) => x.cut);
  console.log("Files are", files);
  for (const ii in files) {
    await apPy("files", "deEuropize", {
      file: files[ii],
      path: paths[ii],
      cut: cuts[ii],
    });
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const removeEmpty = (allfiles, cb) => async (dispatch) => {
  allfiles = allfiles.filter((file) => file.ext === "CSV");
  const files = allfiles.map((x) => x.name);
  const paths = allfiles.map((x) => x.path.replace(/\./g, ""));
  const cuts = allfiles.map((x) => x.cut);
  console.log("Files are", files);
  for (const ii in files) {
    await apPy("files", "cleanEmpty", {
      file: files[ii],
      path: paths[ii],
      cut: cuts[ii],
    });
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const pickSumAttribute = (allfiles) => async (dispatch) => {
  allfiles = allfiles.filter((file) => file.ext === "CSV");
  const files = JSON.stringify(allfiles.map((x) => x.name));
  const paths = JSON.stringify(allfiles.map((x) => x.path.replace(/\./g, "")));
  const cuts = JSON.stringify(allfiles.map((x) => x.cut));

  const result = await apPy("files", "getHeadersMultiple", {
    files,
    paths,
    cuts,
  });
  const { headers } = result.data;
  const headerSet = new Set();
  for (var ii = 0; ii < headers.length; ii++) {
    const thisHeader = headers[ii];
    for (var cc = 0; cc < thisHeader.length; cc++) {
      headerSet.add(thisHeader[cc]);
    }
  }

  dispatch({
    type: "SET_COLLECTION_AGGREGATE_HEADERS",
    aggregateHeaders: Array.from(headerSet),
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setBulkReference = (fileids, ref) => async (dispatch) => {
  dispatch({
    type: "SET_BULK_REFERENCE",
    fileids,
    ref,
  });
  dispatch({
    type: "SET_BULK_HEADERS",
    aggregateHeaders: null,
    bulkCollection: null,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setBulkCut = (bulkCut) => async (dispatch) => {
  dispatch({
    type: "SET_BULK_CUT",
    bulkCut,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const applyBulkCut = (collection, cut) => (dispatch) => {
  const { fileids } = collection;
  dispatch({
    type: "APPLY_BULK_CUT",
    fileids,
    cut,
  });
  dispatch(setBulkCut(null));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const addSumAttr = (files, header) => async (dispatch) => {
  dispatch({
    type: "SET_COLLECTION_AGGREGATE_HEADERS",
    aggregateHeaders: null,
  });

  for (var ii = 0; ii < files.length; ii++) {
    const file = files[ii];
    const result = await apPy("files", "runSum", {
      file: file.name,
      path: file.path.replace(/\./g, ""),
      ref: file.ref,
      cut: file.cut,
      header,
    });
    const { sumValue } = result.data;
    dispatch({
      type: "SET_FILE_ATTRIBUTE",
      fileid: file.id,
      attribute: "sum",
      title: `S(${header})`,
      value: Math.round(100 * parseFloat(sumValue)) / 100,
    });
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getCollection = (projectid, collectionid) => (dispatch) => {
  engine("project", "get", { projectid }).then((result) => {
    if (result.data) {
      const { project } = result.data;
      dispatch({ type: "SET_PROJECT", project });
      const collection = findCollection(project.collections, collectionid);
      console.log("Found collection", collection);
      dispatch({ type: "SET_COLLECTION", collection });
    }
  });
};

const findCollection = (collections, collectionid) => {
  let thisCollection = collections.find((x) => x.id === collectionid);
  if (thisCollection) return thisCollection;
  for (var ii = 0; ii < collections.length; ii++) {
    const subcollections = collections[ii].collections;
    if (subcollections.length) {
      thisCollection = findCollection(subcollections, collectionid);
      if (thisCollection) return thisCollection;
    }
  }
  return null;
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setCollection__ = (collectionid) => (dispatch) => {
  dispatch({ type: "SET_COLLECTION_ID", collectionid });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const pickLinkage = (collectionid) => async (dispatch) => {
  console.log("Picking Linkage for", collectionid);

  const result = await backend("database", "queryTable", {
    dbtable: collectionid,
    limit: 1,
  });
  if (result.data) {
    const data = result.data.results;
    console.log("Data is", data);
    if (data.length) {
      const sourceLinkHeaders = Object.keys(data[0]).filter(
        (x) => x.slice(-4) !== "_9LC"
      );
      dispatch(setSourceLinkHeaders(sourceLinkHeaders));
    }
  }
};

export const setSourceLinkHeaders = (sourceLinkHeaders) => async (dispatch) => {
  dispatch({ type: "ADD_SOURCE_LINK_HEADERS", sourceLinkHeaders });
};
// -------------------------------------------------------
// -------------------------------------------------------
export const setSourceLink = (sourceLink, collectionid) => async (dispatch) => {
  dispatch({ type: "SET_SOURCE_LINK", sourceLink, collectionid });
  dispatch(setSourceLinkHeaders(null));
};
export const setSongLink = (songLink, collectionid) => async (dispatch) => {
  dispatch({ type: "SET_SONG_LINK", songLink, collectionid });
  dispatch(setSourceLinkHeaders(null));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const addSourceRow = (collectionid) => (dispatch) => {
  dispatch({ type: "ADD_SOURCE_ROW", collectionid });
};
export const subtractSourceRow = (collectionid) => (dispatch) => {
  dispatch({ type: "SUBTRACT_SOURCE_ROW", collectionid });
};
export const addSongRow = (collectionid) => (dispatch) => {
  dispatch({ type: "ADD_SONG_ROW", collectionid });
};
export const subtractSongRow = (collectionid) => (dispatch) => {
  dispatch({ type: "SUBTRACT_SONG_ROW", collectionid });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const editUpdateTable = (collection) => (dispatch) => {
  dispatch({ type: "EDIT_UPDATE_TABLE", collection });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateUpdateObjects = (changesArray) => (dispatch) => {
  dispatch({ type: "UPDATE_UPDATE_OBJECTS", changesArray });
};


// -------------------------------------------------------
// -------------------------------------------------------
export const updateUpdateTable = (row, col, value, fileids) => (dispatch) => {
  const fileid = fileids[row];
  dispatch({ type: "UPDATE_UPDATE_TABLE", col, value, fileid });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateSourceTable =
  (row, col, value, collectionid) => (dispatch) => {
    dispatch({ type: "UPDATE_SOURCE_TABLE", row, col, value, collectionid });
  };
export const updateSongTable =
  (row, col, value, collectionid) => (dispatch) => {
    dispatch({ type: "UPDATE_SONG_TABLE", row, col, value, collectionid });
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const uploadSourcesPopup = (collectionid) => (dispatch) => {
  dispatch({ type: "UPLOAD_SOURCES_POPUP", collectionid });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const clearSourceTable = (collectionid) => (dispatch) => {
  dispatch({ type: "CLEAR_SOURCE_TABLE", collectionid });
};
export const clearSongTable = (collectionid) => (dispatch) => {
  dispatch({ type: "CLEAR_SONG_TABLE", collectionid });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const uploadSourceTable = (file, collectionid) => async (dispatch) => {
  const formData = new FormData();
  formData.append("filetoupload", file);
  const result = await uploadAndReadCSV(formData);
  if (result.data) {
    const { table } = result.data;
    dispatch({ type: "UPLOAD_SOURCE_TABLE", table, collectionid });
    dispatch({ type: "UPLOAD_SOURCES_POPUP", collectionid: null });
  }
};
export const uploadSongTable = (file, collectionid) => async (dispatch) => {
  const formData = new FormData();
  formData.append("filetoupload", file);
  const result = await uploadAndReadCSV(formData);
  if (result.data) {
    const { table } = result.data;
    dispatch({ type: "UPLOAD_SONG_TABLE", table, collectionid });
    dispatch({ type: "UPLOAD_SOURCES_POPUP", collectionid: null });
  }
};
// -------------------------------------------------------
// -------------------------------------------------------
export const updateSQLWithSources = (collection) => async (dispatch) => {
  for (var ii = 0; ii < collection.sourceData.length; ii++) {
    dispatch({
      type: "SET_UPDATE_PROGRESS",
      headline: "Updating SQL Table",
      mainline: "Running Update",
      pointer: ii,
      total: collection.sourceData.length,
      status: `Row: ${ii}`,
    });
    const [fromValue, toValue] = collection.sourceData[ii];
    const result = await backend("database", "updateTableSource", {
      dbtable: collection.id,
      sourceLink: collection.sourceLink,
      fromValue,
      toValue,
    });
  }
  dispatch({ type: "SET_UPDATE_PROGRESS", pointer: null });
};

export const updateSQLWithSongs =
  (collection, headerValues, database) => async (dispatch) => {
    for (var ii = 0; ii < collection.songData.length; ii++) {
      dispatch({
        type: "SET_UPDATE_PROGRESS",
        headline: "Updating SQL Table",
        mainline: "Running Update",
        pointer: ii,
        total: collection.songData.length,
        status: `Row: ${ii}`,
      });
      const [fromValue, toValue] = collection.songData[ii];
      const result = await backend("database", "updateTableSong", {
        dbtable: collection.id,
        songLink: collection.songLink,
        fromValue,
        database,
        toValuesString: JSON.stringify(collection.songData[ii]),
        headerValuesString: JSON.stringify(headerValues),
      });
    }
    dispatch({ type: "SET_UPDATE_PROGRESS", pointer: null });
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const addFilesToCollection = (collectionid, dir) => (dispatch) => {
  const fileids = dir.files.map((x) => x.id);
  dispatch({
    type: "ADD_FILES_TO_COLLECTION",
    collectionid,
    fileids,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const makeNewCollection = (dir) => (dispatch) => {
  const data = store.getState();
  const { user } = data.user;

  const fileids = [];
  collectFileIds(dir, fileids);

  const id = md5(user + Date.now() + Math.random());
  const collection = initNewCollection(dir.title, id);
  collection.fileids = fileids;

  dispatch({ type: "NEW_COLLECTION", collection });
};

const collectFileIds = (thisdir, fileids) => {
  for (var ii = 0; ii < thisdir.files.length; ii++) {
    fileids.push(thisdir.files[ii].id);
  }
  for (ii = 0; ii < thisdir.dirs.length; ii++) {
    collectFileIds(thisdir.dirs[ii], fileids);
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const removeCollection = (collectionid) => (dispatch) => {
  const { project } = store.getState().projects;
  const { collectionsRepo } = project;
  const subCollections = collectionsRepo[collectionid].collectionids;
  if (subCollections) {
    for (var ii = 0; ii < subCollections.length; ii++) {
      const subCollection = subCollections[ii];
      dispatch(removeCollection(subCollection.id));
    }
  }
  dispatch({ type: "REMOVE_COLLECTION", collectionid });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const newCollection = (user, headline) => (dispatch) => {
  const id = md5(user + Date.now() + Math.random());
  const collection = initNewCollection(headline, id);

  dispatch({ type: "NEW_COLLECTION", collection });
  dispatch({ type: "DISPLAY_NEW_COLLECTION_POPUP", showPopup: false });
};

// -------------------------------------------------------
// -------------------------------------------------------
const initNewCollection = (headline, id) => {
  return {
    id,
    headline,
    collectionids: [],
    fileids: [],
    files: [],
    parsers: [],
  };
};
// -------------------------------------------------------
// -------------------------------------------------------
export const displayNewCollectionPopup = (showPopup) => (dispatch) => {
  dispatch({
    type: "DISPLAY_NEW_COLLECTION_POPUP",
    showPopup,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const partitionCollection =
  (collection, collectionfiles) => async (dispatch) => {
    const partitions = [];
    const startNewPartition = (header, fileid) => {
      partitions.push({ header, fileids: [fileid] });
    };
    const addToPartition = (partition, fileid) => {
      partition.fileids.push(fileid);
    };
    const isMember = (header, partition) => {
      if (header.length !== partition.header.length) {
        return false;
      }
      let isIt = true;
      for (var ii = 0; ii < header.length; ii++) {
        if (header[ii] !== partition.header[ii]) {
          isIt = false;
        }
      }
      return isIt;
      /*const isec = header.filter( x => partition.header.includes(x) );
    return (
      isec.length === header.length &&
      isec.length === partition.header.length
    )*/
    };

    const csvfiles = collectionfiles.filter((file) => file.ext === "CSV");
    const csvfileids = csvfiles.map((x) => x.id);

    const files = JSON.stringify(csvfiles.map((x) => x.name));
    const paths = JSON.stringify(
      csvfiles.map((x) => x.path.replace(/\./g, ""))
    );
    const cuts = JSON.stringify(csvfiles.map((x) => x.cut));
    const result = await apPy("files", "getHeadersMultiple", {
      files,
      paths,
      cuts,
    });
    const { headers } = result.data;

    const noncsvfileids = collectionfiles
      .filter((file) => file.ext !== "CSV")
      .map((x) => x.id);
    if (noncsvfileids.length) {
      const headline = `${collection.headline}:Misc`;
      const id = md5(collection.id + Date.now() + Math.random());
      const miscPartition = {
        ...initNewCollection(headline, id),
        fileids: noncsvfileids.sort(),
      };
      dispatch({
        type: "ADD_PARTITION",
        collectionid: collection.id,
        partition: miscPartition,
      });
    }

    startNewPartition(headers[0], csvfileids[0]);
    for (var ii = 1; ii < headers.length; ii++) {
      const header = headers[ii];
      let isAssigned = false;
      for (var partition of partitions) {
        if (isMember(header, partition)) {
          addToPartition(partition, csvfileids[ii]);
          isAssigned = true;
        }
      }
      if (!isAssigned) startNewPartition(header, csvfileids[ii]);
    }

    let nFiles = 0;
    for (ii = 0; ii < partitions.length; ii++) {
      const partition = partitions[ii];
      nFiles += partition.fileids.length;
      const headline = `${collection.headline}-CSV${ii}`;
      const id = md5(collection.id + Date.now() + Math.random());
      const collectionPartition = {
        ...initNewCollection(headline, id),
        fileids: partition.fileids.sort(),
      };
      dispatch({
        type: "ADD_PARTITION",
        collectionid: collection.id,
        partition: collectionPartition,
      });
    }
    dispatch({
      type: "CLEAR_COLLECTION_FILES",
      collectionid: collection.id,
      nFiles,
    });
  };
